<script setup>
	const colorMode = useColorMode()
	const activeSearch = useSearch()
	const activeMenu = useMenu()
</script>

<template>
	<div class="md:absolute md:left-[50%] md:-translate-x-[50%] mr-4 md:mr-0">
		<div class="flex items-center cursor-pointer" @click="activeSearch = !activeSearch; activeMenu = false">
			<svg class="mr-1 scale-90 md:scale-100" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
				<path d="M32.7505 19.9668C32.7505 26.4573 27.4888 31.719 20.9982 31.719C14.5075 31.719 9.24585 26.4573 9.24585 19.9668C9.24585 13.4762 14.5075 8.2146 20.9982 8.2146C27.4888 8.2146 32.7505 13.4762 32.7505 19.9668Z" :stroke="colorMode.preference === 'dark' ? 'white' : '#1D1D1B'" stroke-width="1.90576"/>
				<line x1="27.9179" y1="28.5049" x2="36.4939" y2="37.0809" :stroke="colorMode.preference === 'dark' ? 'white' : '#1D1D1B'" stroke-width="1.90576"/>
			</svg>
			<span class="hidden md:inline-block mr-[10px] dark:text-light text-dark text-2xl tracking-[-0.36px] fvs600">{{ $t('generic.search') }}</span>
		</div>
	</div>
</template>