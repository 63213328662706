<script setup>
	const colorMode = useColorMode()
	const activeSearch = useSearch()
	const activeMenu = useMenu()

	const props = defineProps({
		loading: {
			type: Boolean,
		}
	})

	const headerData = reactive({
		version: '',
		scrollPosition: 0,
		scrollMotion: null,
		style: {
			transform: 'translateY(15%) translateX(-50%)',
			opacity: 0,
			pointerEvents: 'auto'
		},
	})

	onMounted(() => {
		window.addEventListener('scroll', handleScroll)

		setTimeout(() => {
			headerData.style = {
				transform: 'translateY(0) translateX(-50%)',
				opacity: 1,
			}
		}, 100)
	})

	onUnmounted(() => {
		window.removeEventListener('scroll', handleScroll)
	})

	const handleScroll = () => {
		let currentScrollPosition = window.scrollY
		if (currentScrollPosition < headerData.scrollPosition) {
			if (currentScrollPosition !== 0) {
				headerData.scrollMotion = 'up'
			} else {
				headerData.scrollMotion = 'top'
			}

			headerData.style = {
				transform: 'translateY(0) translateX(-50%)',
				opacity: 1,
				pointerEvents: 'auto'
			}
		} else {

			if (currentScrollPosition > 0) {
				headerData.scrollMotion = 'down'

				headerData.style = {
					transform: 'translateY(-100%) translateX(-50%)',
					opacity: 1,
					pointerEvents: 'none'
				}
			} else {
				headerData.style = {
					transform: 'translateY(0) translateX(-50%)',
					opacity: 1,
					pointerEvents: 'auto'
				}
			}
		}

		headerData.scrollPosition = window.scrollY
	}

</script>

<template>
	<div v-show="headerData">
		<header
			class="container py-10 fixed left-1/2 top-0 w-full flex items-end z-50 dark:bg-dark bg-white duration-[5000ms]"
			:class="[
        headerData.version,
        headerData.scrollMotion,
				[loading ? 'h-full' : 'h-[128px]']
      ]"
			:style="{
        opacity: headerData.style.opacity,
        transform: headerData.style.transform,
				pointerEvents: headerData.style.pointerEvents
      }"
		>
			<ClientOnly>
				<div class="flex items-center justify-between w-full">
					<NuxtLinkLocale @click="activeSearch = false; activeMenu = false" v-if="colorMode.preference === 'dark'" to="/"><img class="w-[72px] md:w-auto" src="/logo.svg" alt=""></NuxtLinkLocale>
					<NuxtLinkLocale v-else @click="activeSearch = false; activeMenu = false" to="/"><img class="w-[72px] md:w-auto" src="/logo-dark.svg" alt=""></NuxtLinkLocale>
					<SearchUI class="hidden md:block opacity-0 transition-all duration-1000 delay-1000" :class="{'opacity-100' : !loading}" />
					<NavigationUI class="opacity-0 transition-all duration-1000 delay-1000" :class="{'opacity-100' : !loading}" />
				</div>
			</ClientOnly>
		</header>
	</div>
</template>

<style lang="scss" scoped>
header {
	transition: 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);

	&::before {
		transition: 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		width: 1000%;
		position: absolute;
		z-index: 50;
		content: '';
		right: 100%;
		top: 0;
		height: 100%;
		@apply bg-white;

		.dark & {
			@apply bg-dark;
		}
	}

	&::after {
		transition: 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		width: 1000%;
		position: absolute;
		z-index: 50;
		content: '';
		left: 100%;
		top: 0;
		height: 100%;
		@apply bg-white;

		.dark & {
			@apply bg-dark;
		}
	}
}
</style>