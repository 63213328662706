<script setup>

const activeMenu = useMenu()

</script>

<template>
	<div
		class="menu fixed w-full h-full z-50 dark:bg-dark bg-white pt-40 overflow-y-auto"
		:class="[activeMenu ? 'opacity-1 translate-y-0' : 'opacity-0 translate-y-[-100%]']"
	>

		<Menu @click="activeMenu = false" withoutLogo />
	
	</div>
</template>

<style lang="scss" scoped>

	.menu {
		transition: 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	}

</style>