<script setup>
	import debounce from 'lodash.debounce'
	
	const colorMode = useColorMode()
	const activeSearch = useSearch()
	const { locale } = useI18n()

	const colors = ref([
		'red',
		'gold',
		'magenta',
		'cyan',
		'lilac',
		'green'
	])

	const getRandomColor = () => {
		return colors.value[Math.floor(Math.random()*colors.value.length)]
	}

	const { data: search } = await useAPIFetch('Search')
	const searchHints = ref(search.value.body.find(item => item.component === 'Menu'))

	const searchForm = ref(null)
	const values = ref({})
	const isSubmitting = ref(false)
	const results = ref(null)
	const noResults = ref(false)

	const debounceSearch = debounce(() => {
		submitHandler()
	}, 500)

	const submitHandler = async () => {
		noResults.value = false
		results.value = await useUIFetch(locale.value, `Search?query=${values.value.search}`)
		isSubmitting.value = false

		const artists = results.value.body.find(item => item.specific === 'ArtistList').items
		const articles = results.value.body.find(item => item.specific === 'ArticleList').items
		const others = results.value.body.find(item => item.specific === 'Others').items

		noResults.value = (!articles.length && !artists.length && !others.length) ? true : false
	}

	const getLink = (item) => {
		switch (item.type) {
			case 'Artist':
				return { name: 'artist-slug', params: { slug: item.id } }
			case 'Article':
				return { name: 'article-slug', params: { slug: item.id } }
			default:
				return useFindPageEndpoint(item.type)
		}
	}

	watch(activeSearch, () => {
		if(activeSearch.value) {
			document.querySelector('#search').focus()
		}
	})

</script>

<template>
	<div
		class="fixed w-full h-full z-50 dark:bg-dark bg-white pt-40 overflow-y-auto search"
		:class="[activeSearch ? 'opacity-1 translate-y-0' : 'opacity-0 translate-y-[-100%]']"
	>

		<div class="container search-form">
			<FormKit
				ref="searchForm"
				v-model="values"
				type="form"
				:actions="false"
				@submit="isSubmitting = true; debounceSearch()"
			>
				<FormKit
					class="mb-6"
					type="text"
					name="search"
					id="search"
					:placeholder="$t('search.placeholder')"
					validation="required"
					:autofocus="activeSearch"
				>
					<template #prefixIcon>
						<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
							<path d="M32.7505 19.9668C32.7505 26.4573 27.4888 31.719 20.9982 31.719C14.5075 31.719 9.24585 26.4573 9.24585 19.9668C9.24585 13.4762 14.5075 8.2146 20.9982 8.2146C27.4888 8.2146 32.7505 13.4762 32.7505 19.9668Z" :stroke="colorMode.preference === 'dark' ? 'white' : '#1D1D1B'" stroke-width="1.90576"/>
							<line x1="27.9179" y1="28.5049" x2="36.4939" y2="37.0809" :stroke="colorMode.preference === 'dark' ? 'white' : '#1D1D1B'" stroke-width="1.90576"/>
						</svg>
					</template>
				</FormKit>
			</FormKit>

			<div class="mt-6">
				<span class="dark:text-light text-dark mr-5 inline-block text-xl xl:text-2xl fvs600">Hint: </span>
				<span v-if="searchHints">
					<a
						v-for="(hint, index) in searchHints.items"
						:key="index"
						href="#"
						@click.prevent="values.search = hint.label; isSubmitting = true; debounceSearch()"
						class="inline-block text-xl xl:text-2xl fvs600 mr-3 whitespace-nowrap"
						:class="`text-${colors[index]}`"
					>{{ hint.label }}</a>
				</span>
			</div>

		</div>

		<div class="container xl:mt-20">
			<Loader v-if="isSubmitting" />
			<div v-else-if="noResults">
				<h4 class="text-red">{{ $t('search.no-results') }}</h4>
			</div>
			<div v-else-if="!isSubmitting && results">
				<div v-if="results.body?.length">
					<div v-for="component in results.body" :key="component.id">
						<!-- artists -->
						<div v-if="component.component === 'List' && component.specific === 'ArtistList' && component.items.length" class="xl:grid grid-cols-12 gap-8 my-10 xl:my-32">
							<div class="col-span-4">
								<h2 class="text-red mb-6 xl:mb-0">{{ component.title }}</h2>
							</div>
							<div class="col-span-8">
								<div class="md:grid grid-cols-2 gap-8">
									<div v-for="item in component.items" :key="item.id" class="col-span-1">
										<CoverArtist :attrs="item.attrs" :id="item.id" @click="activeSearch = false" />
									</div>
								</div>
							</div>
						</div>
						<!-- articles -->
						<div v-else-if="component.component === 'List' && component.specific === 'ArticleList' && component.items.length" class="xl:grid grid-cols-12 gap-8 my-32">
							<div class="col-span-4">
								<h2 class="text-red mb-6 xl:mb-0">{{ component.title }}</h2>
							</div>
							<div class="col-span-8">
								<div class="md:grid grid-cols-2 gap-8">
									<div v-for="item in component.items" :key="item.id" class="col-span-1">
										<CoverArticle :attrs="item.attrs" :id="item.id" type="article" @click="activeSearch = false" />
									</div>
								</div>
							</div>
						</div>
						<!-- otgers -->
						<div v-else-if="component.component === 'List' && component.specific === 'Others' && component.items.length" class="xl:grid grid-cols-12 gap-8 my-32">
							<div class="col-span-4">
								<h2 class="text-red mb-6 xl:mb-0">{{ component.title }}</h2>
							</div>
							<div class="col-span-8">
								<div class="md:grid grid-cols-2 gap-8">
									<div v-for="item in component.items" :key="item.id" class="col-span-1" @click="activeSearch = false">
										<CoverAlbum v-if="item.type === 'Album'" :attrs="item.attrs" :id="item.id" />
										<NuxtLinkLocale
											v-else
											:to="getLink(item)"
											@mouseenter="hoveredItem = index"
											@mouseleave="hoveredItem = null"
											class="transition-all mr-2 md:mr-3 lg:mr-5 leading-[105%] fvs600 text-[43px]"
											:class="[`text-${getRandomColor()}`, { 'opacity-20': hoveredItem !== null && hoveredItem !== index }]"
											>
											{{ item.label || item.title || item.attrs?.title }}
										</NuxtLinkLocale>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<style lang="scss" scoped>

	.search {
		transition: 0.75s cubic-bezier(0.390, 0.575, 0.565, 1.000);
	}

</style>