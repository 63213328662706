<script setup>
	const colorMode = useColorMode()

	const scrollPositionTrigger = ref(500)
	const show = ref(false)

	onMounted(() => {
		window.addEventListener('scroll', handleScroll)
	})

	onUnmounted(() => {
		window.removeEventListener('scroll', handleScroll)
	})

	const handleScroll = () => {
		if (window.scrollY > scrollPositionTrigger.value) {
			show.value = true
		} else {
			show.value = false
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		})
	}
</script>

<template>
	<ClientOnly>
		<div
			class="fixed right-4 md:right-8 top-[90%] md:top-[50%] cursor-pointer transition-all duration-500 hover:scale-110"
			:class="[show ? 'z-50 opacity-100' : 'z-0 opacity-0']"
			@click="scrollToTop"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
				<circle cx="32" cy="32" r="30.5" transform="rotate(-90 32 32)" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="3"/>
				<path d="M32 22L32 41.9999" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="3"/>
				<path d="M24 29.5L32 21.5L40 29.5" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/>
			</svg>
		</div>
	</ClientOnly>
</template>