<script setup>

import Lenis from 'lenis'

const lenis = ref(null)

const route = useRoute()
const langSwitch = ref(route.query.ls)

const isHomepage = route.name.includes('index___')

const activeSearch = useSearch()
const activeMenu = useMenu()
const webLoading = ref((langSwitch.value || !isHomepage) ? false : true)

const isOverflowActive = ref((langSwitch.value || !isHomepage) ? false : true)

const raf = (time) => {
	lenis.value.raf(time)
  requestAnimationFrame(raf)
}

useHead({
  bodyAttrs: {
		class: () => isOverflowActive.value ? "overflow-hidden" : ""
  },
});

onMounted(() => {
	if (langSwitch.value) navigateTo({ query: null });
	lenis.value = new Lenis()
	requestAnimationFrame(raf)
})

const onLoaded = () => {
	webLoading.value = false
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth',
	})
}

watch(() => [activeSearch.value, activeMenu.value, webLoading.value], () => {
  if(activeSearch.value || activeMenu.value || webLoading.value) {
		isOverflowActive.value = true
	} else {
		isOverflowActive.value = false
	}
});

</script>

<template>
	<Search data-lenis-prevent />
	<Navigation data-lenis-prevent />
	<BackToTop v-if="!webLoading" />
	<AppHeader :loading="webLoading" />
	<div v-if="webLoading">
		<WebLoader @loaded="onLoaded()" />
	</div>
	<div class="opacity-0 relative top-10" :class="{'loaded' : !webLoading}">
		<div class="pt-32 xl:pt-44">
			<slot v-if="!webLoading" />
		</div>
		<AppFooter />
	</div>
</template>

<style lang="scss" scoped>

	.loaded {
		transition: 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		transition-delay: 1000ms;
		top: 0;
		opacity: 1;
	}

</style>