<script setup>

	const sizerCookie = useCookie('sizerCookie', {
		default: () => 4,
		watch: true
	})

	const { $event } = useNuxtApp()
	import { theme } from '#tailwind-config'
	const screensWidth = ref({ ... theme.screens })
	const sizerValue = ref(sizerCookie.value || "3")

	const setSizerValue = (value) => {
		sizerValue.value = value
		localStorage.setItem("supraphon-sizer", value)
		sizerCookie.value = parseInt(value)
		$event('setSizerValue', value)
	}
	
	onMounted(() => {
		if (!localStorage.getItem("supraphon-sizer")){
			if (window.innerWidth >= parseInt(screensWidth.value.fullHD.replace('px', ''))) {
				localStorage.setItem("supraphon-sizer", "4")
				sizerCookie.value = 4
			} else {
				localStorage.setItem("supraphon-sizer", "3")
				sizerCookie.value = 3
			}
		}
	})
</script>

<template>
	<div class="flex items-center">
		<span class="inline-block mr-[10px] dark:text-light text-dark text-xs tracking-[0.18px] fvs600">Size</span>
		<input @change="(e) => setSizerValue(e.target.value)" type="range" min="3" max="5" step="1" :value="sizerValue">
	</div>
</template>

<style lang="scss" scoped>
/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 60px;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: theme('colors.dark');
  border-radius: 0px;
  height: 2px;

	.dark & {
		background-color: theme('colors.light');
	}
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -5px; /* Centers thumb on the track */
  background-color: theme('colors.light');
  border-radius: 100%;
	border: 2px solid theme('colors.dark');
  height: 12px;
  width: 12px;

	.dark & {
		background-color: theme('colors.dark');
		border: 2px solid theme('colors.light');
	}
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: theme('colors.dark');
  border-radius: 0px;
  height: 2px;

	.dark & {
		background-color: theme('colors.light');
	}
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: theme('colors.light');
  border: none; /*Removes extra border that FF applies*/
  border-radius: 100%;
	border: 2px solid theme('colors.dark');
  height: 12px;
  width: 12px;

	.dark & {
		background-color: theme('colors.dark');
		border: 2px solid theme('colors.light');
	}
}
</style>