<template>
	<div class="container mt-12 md:mt-[100px] mb-10">
		<div class="flex flex-col md:flex-row justify-between">
			<span class="order-2 md:order-1 dark:text-light text-sm leading-[130%] mt-10 md:mt-0">
				&copy; {{ new Date().getFullYear() }} Supraphon
			</span>
			<div class="md:flex order-1 md:order-2">
				<a href="/pdf/gdpr.pdf" target="_blank" class="block md:inline-block dark:text-light text-sm leading-[130%] link mr-5">
					Terms &amp; conditions
				</a>
				<a href="#" class="cky-banner-element block md:inline-block dark:text-light text-sm leading-[130%] link ">{{ $t('cookies.settings') }}</a>
			</div>
		</div>
	</div>
</template>