<script setup>
	const colorMode = useColorMode()

	const socialLinks = ref([
		{ component: 'SocialLinkFacebook', url: 'https://www.facebook.com/Supraphon/' },
		{ component: 'SocialLinkInstagram', url: 'https://www.instagram.com/supraphon/' },
		{ component: 'SocialLinkTikTok', url: 'https://www.tiktok.com/@supraphon' },
		{ component: 'SocialLinkYoutube', url: 'https://www.youtube.com/@supraphon' },
		{ component: 'SocialLinkTwitter', url: 'https://twitter.com/SupraphonCZ' },
		{ component: 'SocialLinkLinkedin', url: 'https://www.linkedin.com/company/supraphon/' }
	])

	const props = defineProps({
  	withoutLogo: {
			type: Boolean,
			default: false
		},
	})

</script>

<template>
	<div class="container xl:pt-10">
		<div class="grid grid-cols-12 gap-4">
			<div :class="[withoutLogo ? 'hidden xl:block' : '']" class="mb-10 lg:mb-0 col-span-12 lg:col-span-3 xl:col-span-4">
				<ClientOnly v-if="!withoutLogo">
					<NuxtLinkLocale class="logo-img" v-if="colorMode.preference === 'dark'" to="/"><img class="w-[72px] md:w-auto" src="/logo.svg" alt=""></NuxtLinkLocale>
					<NuxtLinkLocale class="logo-img" v-else to="/"><img src="/logo-dark.svg" alt=""></NuxtLinkLocale>
				</ClientOnly>
			</div>
			<div class="col-span-12 lg:col-span-5 md:col-span-6 xl:col-span-5">
				<div class="mb-[60px]">
					<h2 class="text-lilac mb-5">{{ $t('menu.title.artist') }}</h2>
					<ul>
						<li class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="for-artists">{{ $t('menu.for-artists') }}</NuxtLinkLocale>
						</li>
						<li class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="artists" >{{ $t('menu.representing') }}</NuxtLinkLocale>
						</li>
						<li>
							<a class="text-lg leading-[140%] dark:text-light text-dark link in-list" href="https://prodejhudbu.cz/" target="_blank">Prodejhudbu.cz</a>
						</li>
					</ul>
				</div>
				<div class="mb-[60px]">
					<h2 class="text-gold mb-5">{{ $t('menu.title.i-want-listen') }}</h2>
					<ul>
						<li class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="artists" >{{ $t('menu.artists') }}</NuxtLinkLocale>
						</li>
						<li v-if="$i18n.locale === 'cs'" class="mb-1">
							<NuxtLinkLocale
								class="text-lg leading-[140%] dark:text-light text-dark link in-list"
								:to="{ name: 'artists', query: { maingenre: 693 } }"
							>
								{{ $t('menu.spoken-word') }}
							</NuxtLinkLocale>
						</li>
						<li class="mb-1">
							<a class="text-lg leading-[140%] dark:text-light text-dark link in-list" href="https://www.supraphonline.cz/" target="_blank">Supraphonline.cz</a>
						</li>
						<li v-if="$i18n.locale === 'cs'" class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="podcasts">{{ $t('menu.podcasts') }}</NuxtLinkLocale>
						</li>
					</ul>
				</div>
				<div class="mb-[60px] md:mb-0">
					<h2 class="text-green mb-5">{{ $t('menu.title.i-want-music') }}</h2>
					<ul>
						<li class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="booking">{{ $t('menu.booking') }}</NuxtLinkLocale>
						</li>
						<li class="mb-1">
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="license">{{ $t('menu.license') }}</NuxtLinkLocale>
						</li>
						<li>
							<NuxtLinkLocale class="text-lg leading-[140%] dark:text-light text-dark link in-list" to="influencer-marketing">{{ $t('menu.influencer-marketing') }}</NuxtLinkLocale>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-span-12 lg:col-span-4 md:col-span-6 xl:col-span-3">
				<div class="mb-10 md:mb-[60px]">
					<h5 class="text-red mb-5 md:mt-3.5">{{ $t('menu.title.what-is-new') }}</h5>
					<ul>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="articles">{{ $t('menu.news') }}</NuxtLinkLocale>
						</li>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="new-releases">{{ $t('menu.new-releases') }}</NuxtLinkLocale>
						</li>
						<li>
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="newsletter">{{ $t('menu.newsletter') }}</NuxtLinkLocale>
						</li>
					</ul>
				</div>
				<div class="mb-10 md:mb-[60px]">
					<h5 class="text-red mb-5">{{ $t('menu.about-us') }}</h5>
					<ul>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="story-of-supraphon">{{ $t('menu.story-of-supraphon') }}</NuxtLinkLocale>
						</li>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="contact">{{ $t('menu.contacts') }}</NuxtLinkLocale>
						</li>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="prague-store">{{ $t('menu.prague-store') }}</NuxtLinkLocale>
						</li>
						<li>
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="career">{{ $t('menu.career') }}</NuxtLinkLocale>
						</li>
					</ul>
				</div>
				<div class="mb-10 md:mb-[60px]">
					<h5 class="text-red mb-5">{{ $t('menu.partners') }}</h5>
					<ul>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="for-media">{{ $t('menu.for-media') }}</NuxtLinkLocale>
						</li>
						<li class="mb-2">
							<NuxtLinkLocale class="text-sm leading-[130%] dark:text-light text-dark link in-list" to="for-business">{{ $t('menu.for-business') }}</NuxtLinkLocale>
						</li>
						<li>
							<a class="text-sm leading-[130%] dark:text-light text-dark link in-list" href="https://supraphon.brandcloud.pro/link/CGr697oW" target="_blank" v-html="$t('menu.logos')" />
						</li>
					</ul>
				</div>
				<div>
					<h5 class="text-red mb-5">{{ $t('menu.follow-us') }}</h5>
					<div class="-ml-2.5"><Socials :items="socialLinks" isSmall /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
h2 {
	font-size: 43px;
}

.no-logo {
	.logo-img {
		@media (min-width: 768px) {
			display: none;
		}
	}
}
</style>