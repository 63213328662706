<script setup>
	const colorMode = useColorMode()
	const activeMenu = useMenu()
	const activeSearch = useSearch()
</script>

<template>
	<div class="flex">
		<Sizer class="sizer mr-12 hidden 2xl:flex" />
		<SearchUI class="block md:hidden" />
		<!-- <LangSwitcher /> -->
		<svg
			v-if="colorMode.preference === 'dark'"
			class="cursor-pointer mr-[10px] md:mr-[31px]"
			@click="colorMode.preference = colorMode.preference === 'dark' ? 'light' : 'dark'"
			xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none"
		>
			<mask id="path-1-inside-1_1248_3830" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'">
			<path d="M27.4281 22C27.4281 24.6036 25.3174 26.7143 22.7138 26.7143C20.1102 26.7143 17.9995 24.6036 17.9995 22C17.9995 19.3964 20.1102 17.2857 22.7138 17.2857C25.3174 17.2857 27.4281 19.3964 27.4281 22Z"/>
			</mask>
			<path d="M27.4281 22C27.4281 24.6036 25.3174 26.7143 22.7138 26.7143C20.1102 26.7143 17.9995 24.6036 17.9995 22C17.9995 19.3964 20.1102 17.2857 22.7138 17.2857C25.3174 17.2857 27.4281 19.3964 27.4281 22Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M25.0709 22C25.0709 23.3018 24.0156 24.3571 22.7138 24.3571V29.0714C26.6192 29.0714 29.7852 25.9054 29.7852 22H25.0709ZM22.7138 24.3571C21.412 24.3571 20.3567 23.3018 20.3567 22H15.6424C15.6424 25.9054 18.8084 29.0714 22.7138 29.0714V24.3571ZM20.3567 22C20.3567 20.6982 21.412 19.6428 22.7138 19.6428V14.9286C18.8084 14.9286 15.6424 18.0945 15.6424 22H20.3567ZM22.7138 19.6428C24.0156 19.6428 25.0709 20.6982 25.0709 22H29.7852C29.7852 18.0945 26.6192 14.9286 22.7138 14.9286V19.6428Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" mask="url(#path-1-inside-1_1248_3830)"/>
			<path d="M32.1431 20.7857L32.1431 22.7857L37.6431 22.7857L37.6431 20.7857L32.1431 20.7857Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M7.78564 20.7857L7.78564 22.7857L13.2856 22.7857L13.2856 20.7857L7.78564 20.7857Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M21.5005 12.5714L23.5005 12.5714L23.5005 7.07141L21.5005 7.07141L21.5005 12.5714Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M21.5005 36.9285L23.5005 36.9285L23.5005 31.4285L21.5005 31.4285L21.5005 36.9285Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M30.2399 27.8083L28.8257 29.2225L32.7148 33.1116L34.129 31.6974L30.2399 27.8083Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M13.0163 10.5852L11.6021 11.9995L15.4911 15.8885L16.9054 14.4743L13.0163 10.5852Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M28.5228 14.4743L29.937 15.8885L33.8261 11.9995L32.4119 10.5852L28.5228 14.4743Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<path d="M11.3001 31.6974L12.7144 33.1116L16.6034 29.2225L15.1892 27.8083L11.3001 31.6974Z" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
		</svg>
		<svg
			v-else @click="colorMode.preference = colorMode.preference === 'dark' ? 'light' : 'dark'"
			class="cursor-pointer mr-[10px] md:mr-[31px]"
			xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none"
		>
			<mask id="path-1-inside-1_5220_4146" fill="white">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M35.0987 24.1708C33.7179 24.7952 32.1851 25.1428 30.5711 25.1428C24.496 25.1428 19.5711 20.2179 19.5711 14.1428C19.5711 12.5288 19.9187 10.996 20.5431 9.61523C14.6347 10.6437 10.1426 15.7972 10.1426 21.9999C10.1426 28.9429 15.771 34.5713 22.714 34.5713C28.9167 34.5713 34.0702 30.0792 35.0987 24.1708Z"/>
			</mask>
			<path d="M35.0987 24.1708L37.4209 24.575L38.1845 20.1884L34.1275 22.023L35.0987 24.1708ZM20.5431 9.61523L22.6909 10.5864L24.5255 6.52944L20.1389 7.29301L20.5431 9.61523ZM34.1275 22.023C33.0458 22.5122 31.8436 22.7856 30.5711 22.7856V27.4999C32.5266 27.4999 34.39 27.0782 36.0699 26.3185L34.1275 22.023ZM30.5711 22.7856C25.7978 22.7856 21.9283 18.9161 21.9283 14.1428H17.214C17.214 21.5197 23.1942 27.4999 30.5711 27.4999V22.7856ZM21.9283 14.1428C21.9283 12.8703 22.2018 11.6681 22.6909 10.5864L18.3954 8.64402C17.6357 10.324 17.214 12.1873 17.214 14.1428H21.9283ZM12.4997 21.9999C12.4997 16.9626 16.1484 12.7728 20.9474 11.9375L20.1389 7.29301C13.121 8.51462 7.78544 14.6318 7.78544 21.9999H12.4997ZM22.714 32.2142C17.0728 32.2142 12.4997 27.6411 12.4997 21.9999H7.78544C7.78544 30.2447 14.4692 36.9285 22.714 36.9285V32.2142ZM32.7765 23.7666C31.9411 28.5655 27.7513 32.2142 22.714 32.2142V36.9285C30.0821 36.9285 36.1993 31.5929 37.4209 24.575L32.7765 23.7666Z" fill="#333333" mask="url(#path-1-inside-1_5220_4146)"/>
		</svg>
		<svg
			v-if="!activeMenu" class="cursor-pointer" @click="activeMenu = !activeMenu; activeSearch = false" xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
			<line x1="10.1445" y1="13.1426" x2="35.2874" y2="13.1426" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="2"/>
			<line x1="10.1445" y1="22.5703" x2="35.2874" y2="22.5703" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="2"/>
			<line x1="10.1445" y1="32" x2="35.2874" y2="32" :stroke="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'" stroke-width="2"/>
		</svg>
		<svg
			v-else
			class="cursor-pointer" @click="activeMenu = !activeMenu; activeSearch = false" width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="12.3945" y="30.7266" width="25.9286" height="2.35714" transform="rotate(-45 12.3945 30.7266)" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
			<rect x="14.0586" y="12.3926" width="25.9286" height="2.35714" transform="rotate(45 14.0586 12.3926)" :fill="colorMode.preference === 'dark' ? '#F5F5F5' : '#1D1D1B'"/>
		</svg>

	</div>
</template>

<style lang="scss" scoped>

	.sizer {
		opacity: 0;
		pointer-events: none;

		.has-sizer & {
			opacity: 1;
			pointer-events: all;
		}
	}

</style>