<script setup>

const firstRowActive = ref(false)
const secondRowActive = ref(false)
const thirdRowActive = ref(false)

const firstTimeout = ref(null)
const secondTimeout = ref(null)
const thirdTimeout = ref(null)

const lettersTimeout = ref(null)

const wordsByLetters = ref([
	[
		{letter: 'r', color: 'cyan'},
		{letter: 'a', color: ' dark:text-light'},
		{letter: 'p', color: 'magenta'},
	],
])

const activeWord = ref([...wordsByLetters.value[0]])
const activeIndex = ref(0)

const emit = defineEmits(['loaded'])

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

onMounted(() => {

	if (!localStorage.getItem("supraphon-loaded")){
		wordsByLetters.value.push(
			[
				{letter: 'rock', color: 'green'},
			],
			[
				{letter: 'fresh', color: 'magenta'},
			],
			[
				{letter: 'classic', color: 'red'},
			],
			[
				{letter: 'gold', color: 'gold'},
			],
			[
				{letter: 'ahoj', color: 'white'},
			],
			[
				{letter: 'r', color: 'cyan'},
				{letter: 'a', color: ' dark:text-light'},
				{letter: 'p', color: 'magenta'},
			],
		)
	}

	firstTimeout.value = setTimeout(() => {
		firstRowActive.value = true
	}, 500)
	secondTimeout.value = setTimeout(() => {
		secondRowActive.value = true
	}, 700)
	thirdTimeout.value = setTimeout(() => {
		thirdRowActive.value = true
	}, 900)

	lettersTimeout.value = setTimeout(() => {
		letters()
	}, 1250)

	const letters = async () => {
		let n = 1

		while (n < wordsByLetters.value.length) {
			for (let index = 0; index < wordsByLetters.value[activeIndex.value].length; index++) {
				activeWord.value.pop()
			}
			activeWord.value = []

			activeIndex.value++
			

			for (let index = 0; index < wordsByLetters.value[activeIndex.value].length; index++) {
				activeWord.value.push(wordsByLetters.value[activeIndex.value][index])
			}

			await delay(300)

			n++;
		}

		firstTimeout.value = setTimeout(() => {
			firstRowActive.value = false
		}, 900)
		secondTimeout.value = setTimeout(() => {
			secondRowActive.value = false
		}, 700)
		thirdTimeout.value = setTimeout(() => {
			thirdRowActive.value = false
		}, 500)

		setTimeout(() => {
			if (!localStorage.getItem("supraphon-loaded")){
				const today = new Date()
				const loaderStorage = {value: "loaded", timestamp: today.setDate(today.getDate() + 7)}
				localStorage.setItem("supraphon-loaded", JSON.stringify(loaderStorage));
			} else {
				const loadedObject = JSON.parse(localStorage.getItem("supraphon-loaded"))
				const loadedDate = loadedObject.timestamp

				if(new Date().getTime() > new Date(loadedDate).getTime()) {
					localStorage.removeItem("supraphon-loaded")
				}
			}
			emit('loaded')
		}, 1100)
	}
})

onUnmounted(() => {
	clearTimeout(firstTimeout)
	clearTimeout(secondTimeout)
	clearTimeout(thirdTimeout)
	clearTimeout(lettersTimeout)
})

</script>
<!-- text-[60px] sm:text-[80px] md:text-[100px] lg:text-[125px] xl:text-[150px] 2xl:text-[227px] -->
<template>
	<div class="container loader relative py-10 pt-10 2xl:pt-20 fvs600 leading-[76%] 2xl:leading-[80%] 2xl:tracking-[-3.405px] uppercase z-50">
		<div v-if="firstRowActive"><span class="dark:text-light">S</span><span class="text-red">u</span><span class="text-gold">p</span></div>
		<div v-if="secondRowActive">
			<span v-for="(letter, index) in activeWord" :key="index" :class="`text-${letter.color}`">{{ letter.letter }}</span>
			<span>&nbsp;</span>
		</div>
		<div v-if="thirdRowActive"><span class="dark:text-light">H</span><span class="text-lilac">o</span><span class="text-green">n</span></div>
	</div>
</template>

<style lang="scss" scoped>
.loader {
	font-size: 15vmin;
	font-weight: 600;

	@media (min-width: 1024px) {
		font-size: 20vmin;
  }
	@media (min-width: 1440px) {
		font-size: 25vmin;
  }
}
</style>